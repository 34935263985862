@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  @keyframes lineAnim {
    0% {
      left: -40%;
    }
    50% {
      left: 20%;
      width: 80%;
    }
    100% {
      left: 100%;
      width: 100%;
    }
  }

  .animate-lineAnim {
    animation: lineAnim 1s linear infinite;
  }
  /* this class is for up and down arrows are always visible for Input type number*/
.number-input::-webkit-inner-spin-button,
.number-input::-webkit-outer-spin-button {
  opacity: 1;
}

.placeholder-text-gray-900::placeholder {
  color:#4b5563;
  opacity: 1; 
}
.placeholder-text-gray-300::placeholder {
  color:#d1d5db;
  opacity: 1; 
}
}
